window.googleApiKey = "AIzaSyB9d5Gnuu7J3pfuxOSvNA4TIOTEnGHbTwU";

// *** Utils ***
import "./js/utils/translate.js";
import "./js/utils/utils.js";
import "./js/editor-front.js";
import "./js/utils/loader.js";
import "./js/app.js";
import "./js/cookies.js";
import "./js/contactmodal.js";
import "./js/utils/css.js";
import "./js/utils/modal.js";
// import "./js/tools/asana/logs.js";
import "./js/contact.js";

// *** Tools ***
import "./swiper/js/swiper.js";
import "./js/tools/scrolleasing.js";
import "./js/tools/scrolleasing.js";
import "./js/tools/typinganimation.js";
import "./js/tools/vc-animation.js";
import "./js/tools/dynamic-components.js";
import "./js/tools/aos.js";
// import "./js/tools/dotlottie-player.js";
import "./js/tools/vc-map.js";
import "./js/tools/vc-carousel.js";
import "./js/tools/vc-counter.js";

import "./js/tools/nav.js";
import "./js/watcher.js";
// import "./allsenses/js/fullpagescroll.js"


// Cookies consent - load cookies after accept
window.addEventListener("cookie.accepted", function(event) {

    gtag('consent', 'update', {
        'functionality_storage': 'granted',
    });

    if (checkCookie("analyticalCookies")) {

        //personalization_storage
        //analytics_storage
        gtag('consent', 'update', {
            'personalization_storage': 'granted',
            'analytics_storage': 'granted',
        });

        // Load analytical cookies
        console.log("Load analytical cookies");
        addScript("/scripts/js/tools/asana/bowser.js", "text/javascript", function() {
            console.log("Bowser loaded");
        //     addScript("/scripts/js/tools/asana/asana.js", "module", function() {
        //         console.log("Asana loaded");
        //     })
        })
    }

    if (checkCookie("marketingCookies")) {
        // Load marketing cookies
        console.log("Load marketing cookies");

        //ad_storage
        //ad_user_data
        //ad_personalization
        gtag('consent', 'update', {
            'ad_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
        });

    }

});

window.getComputedStyleByGlobalCompositeOperatorWithExclude = function(key, value) {
    return getComputedStyle(document.body)[key].split(value).join("");
};

setTimeout(function(){
	$(window).trigger("load");
},100);


